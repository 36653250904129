
















  import Vue from 'vue'
  import { Component, Prop } from 'vue-property-decorator'
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
  import 'swiper/css/swiper.css'

  @Component({
    components: {
      Swiper,
      SwiperSlide,
      OneCmsProductFetcherComponent: () => import('~/components/organisms/grid/shared/OneCmsProductFetcherComponent.vue'),
      OneCmsDynamicComponent: () => import('~/components/organisms/grid/shared/OneCmsDynamicComponent.vue'),
    },
  })
  export default class OneCmsProductsRotator extends Vue {
    name: string = 'OneCmsProductsRotator'

    @Prop({
      required: true,
      type: Array,
    })
    readonly products!: Array<string>

    @Prop({
      required: true,
      type: String,
    })
    readonly html!: string

    @Prop({
      required: true,
      type: Number,
    })
    readonly slidesPerView!: number

    @Prop({
      required: true,
      type: Number,
    })
    readonly spaceBetween!: number

    @Prop({
      required: true,
      type: Boolean,
    })
    readonly navigation!: Boolean

    get swiperOptions() {
      return {
        spaceBetween: 50,
        slidesPerView: 1,
        centeredSlides: true,
        breakpoints: {
          1450: {
            centeredSlides: false,
            slidesPerView: 6,
            spaceBetween: 80,
          },
          1024: {
            centeredSlides: false,
            slidesPerView: 4,
            spaceBetween: 20,
          },
          782: {
            centeredSlides: false,
            slidesPerView: 3,
            spaceBetween: 50,
          },
          640: {
            centeredSlides: false,
            slidesPerView: 2,
            spaceBetween: 10,
          },
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      }
    }
  }
